.forgot-password {
  padding-top: 144px;
  height: 100vh;
}
.forgot-password__block {
  max-width: 640px;
  margin: 0 auto;
}
.forgot-password__title {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 32px;
  color: #232536;
}
.forgot-password__form {
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  row-gap: 40px;
}
.forgot-password__form-control {
  display: flex;
  flex-direction: column;
}
.forgot-password__form-control input {
  max-width: 500px;
  margin-top: 3px;
  padding: 12px 0 12px 15px;
  outline: none;
  border: 2px solid #f00;
  border-radius: 5px;
  font-family: Poppins;
  font-weight: normal;
  font-size: 15px;
  color: #f00;
  &::placeholder {
    font-family: Poppins;
    font-weight: normal;
    font-size: 13px;
    color: #f00;
  }
}
.forgot-password__form-control span {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: #f00;
  padding-left: 2px;
  padding-top: 3px;
}
.forgot-password__form-btn {
  max-width: 300px;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 12px 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: #ff4500;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

@media only screen and (max-width: 680px) {
  .forgot-password__title {
    font-size: 27px;
    transition: 0.3s;
  }
}
@media only screen and (max-width: 580px) {
  .forgot-password {
    padding-top: 130px;
    transition: 0.3s;
  }
  .forgot-password__title {
    font-size: 25px;
    line-height: 37px;
    transition: 0.3s;
  }
  .forgot-password__form {
    margin-top: 35px;
    row-gap: 35px;
  }
}
@media only screen and (max-width: 346px) {
  .forgot-password__title {
    font-size: 22px;
    transition: 0.3s;
  }
  .forgot-password {
    padding-top: 120px;
    transition: 0.3s;
  }
}
