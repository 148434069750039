.loader__container {
  max-width: 100%;
  height: 100%;
  backdrop-filter: blur(7px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  transform: scale(0);
  transition: 0.3s;
}
.loader__key {
  transform: scale(1);
  transition: 0.3s;
  .loader {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 40%;
    left: 50%;
  }
}
.loader {
  border: 5px solid;
  border-color: #ff3d00 transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 830px) {
  .loader__key {
    .loader {
      left: 45%;
    }
  }
}
@media only screen and (max-width: 530px) {
  .loader__key {
    .loader {
      left: 40%;
    }
  }
}
@media only screen and (max-width: 361px) {
  .loader__key {
    .loader {
      left: 37%;
    }
  }
}
@media only screen and (max-width: 300px) {
  .loader__key {
    .loader {
      left: 32%;
    }
  }
}
@media only screen and (max-width: 255px) {
  .loader__key {
    .loader {
      left: 29%;
    }
  }
}
