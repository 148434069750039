.signUp {
  padding-top: 144px;
  height: 100vh;
}
.signUp__block {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  .signUp__title {
    font-family: Poppins;
    font-weight: 500;
    font-size: 25px;
    color: #000;
  }
}
.signUp__form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  button {
    max-width: 300px;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 12px 0;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
    background: blueviolet;
    color: #fff;
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
      rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  }
}
.signUp__form-control {
  label {
    display: flex;
    flex-direction: column;
    font-family: Poppins;
    font-weight: 500;
    font-size: 18px;
    color: #000;
    position: relative;
  }
  label svg {
    position: absolute;
    top: 45px;
    right: 15px;
    cursor: pointer;
  }
  input {
    max-width: 700px;
    margin-top: 3px;
    padding: 12px 0 12px 15px;
    outline: none;
    border: 1px solid blueviolet;
    border-radius: 5px;
    color: blueviolet;
    font-size: 15px;
    &::placeholder {
      font-family: Poppins;
      font-weight: 400;
      font-size: 12px;
      color: blueviolet;
    }
  }
  span {
    font-family: Poppins;
    font-weight: 400;
    font-size: 12px;
    color: red;
  }
}

@media only screen and (max-width: 860px) {
  .signUp {
    padding-top: 114px;
    transition: 0.3s;
  }
}
@media only screen and (max-width: 361px) {
  .signUp {
    padding-top: 103px;
    transition: 0.3s;
  }
  .signUp__block {
    .signUp__title {
      font-size: 20px;
    }
    transition: 0.3s;
  }
  .signUp__form {
    margin-top: 30px;
    row-gap: 30px;
    button {
      max-width: 250px;
      padding: 10px 0;
      font-size: 16px;
    }
  }
  .signUp__form-control {
    label {
      font-size: 16px;
    }
    input {
      padding: 8px 0 8px 13px;
      font-size: 13px;
    }
    label svg {
      top: 37px;
    }
  }
}
