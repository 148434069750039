.site-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-bottom: 1px solid #232536;
  background: #fff;
  transition: 0.3s;
}
.site-header__logo {
  display: flex;
  align-items: center;
  column-gap: 7px;
  cursor: pointer;
}
.site-header__logo svg {
  width: 37px;
  height: 37px;
  flex-shrink: 0;
  transition: 0.3s;
}
.site-header__logo h2 {
  font-family: Poppins;
  font-size: 21px;
  font-weight: 500;
  transition: 0.3s;
}
.site-header__logo:hover svg {
  color: #8a2be2;
}
.site-header__logo:hover h2 {
  color: #8a2be2;
}
.site-header__block {
  padding-top: 25px;
  padding-bottom: 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
}
.site-header__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 70px;
}
.site-header__item {
  cursor: pointer;
}
.site-header__item:nth-child(1) svg {
  width: 27px;
  height: 27px;
  transition: 0.3s;
}
.site-header__item:nth-child(1):hover svg {
  color: #8a2be2;
}
.site-header__item:nth-child(6) svg {
  width: 27px;
  height: 27px;
}
.site-header__item:nth-child(6):hover svg {
  color: #8a2be2;
}
.site-header__item a {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  color: #000;
  padding-bottom: 3px;
  outline: none;
  border-bottom: 2px solid transparent;
}
.site-header__item a:hover {
  color: #8a2be2;
  transition: 0.3s;
}
.site-header__item .active {
  border-bottom: 2px solid #2eec77;
}
.site-header__item button {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  background: #fff;
  color: #000;
  padding: 3px 7px;
  outline: none;
  border: 2px solid transparent;
  border-radius: 7px;
}
.site-header__item button:hover {
  border-color: #000;
  transition: 0.3s;
}
.sign-out {
  position: relative;
  box-sizing: border-box;
}
.sign-out input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.sign-out label {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.sign-out label:before {
  content: "";
  width: 50px;
  height: 22px;
  background: #4bd865;
  position: relative;
  display: inline-block;
  border-radius: 46px;
  box-sizing: border-box;
  transition: 0.2s ease-in;
}
.sign-out label:after {
  content: "";
  position: absolute;
  width: 16.5px;
  height: 16.5px;
  border-radius: 50%;
  left: 30.7px;
  top: 3px;
  background: #fff;
  box-sizing: border-box;
  transition: 0.2s ease-in;
}
.sign-out input[type="checkbox"]:checked + label:before {
  background: #ccc;
}
.sign-out input[type="checkbox"]:checked + label:after {
  left: 3.1px;
}
.site-header__menu-logo {
  transition: 0.3s;
  display: none;
}
.site-header__menu-logo svg {
  width: 30px;
  height: 30px;
  transition: 0.3s;
}
.site-header__mobile {
  max-width: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1;
  background: rgba(0, 0, 0, 0.8);
  transform: translateX(-101%);
  transition: 0.1s;
}
.site-header__mobile-list {
  max-width: 77%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 55px;
  overflow-y: scroll;
  padding: 25px 20px;
  background: #fff;
  transform: translateX(-101%);
  transition: 0.1s;
}
.site-header__mobile-key {
  transform: translateX(0);
}
.site-header__mobile-key + .site-header__mobile-list {
  transform: translateX(0);
}
.site-header__mobile-item {
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.site-header__mobile-logo {
  display: flex;
  align-items: center;
  column-gap: 7px;
  cursor: pointer;
}
.site-header__mobile-logo svg {
  width: 33px;
  height: 33px;
  flex-shrink: 0;
  transition: 0.3s;
}
.site-header__mobile-logo h2 {
  font-family: Poppins;
  font-size: 19px;
  font-weight: 500;
  transition: 0.3s;
}
.site-header__mobile-logo:hover svg {
  color: #8a2be2;
}
.site-header__mobile-logo:hover h2 {
  color: #8a2be2;
}
.site-header__mobile-darkmode svg {
  width: 27px;
  height: 27px;
  cursor: pointer;
  transition: 0.3s;
}
.site-header__mobile-darkmode:hover svg {
  color: #8a2be2;
}
.site-header__mobile-item-left-content {
  display: flex;
  align-items: center;
  column-gap: 7px;
  cursor: pointer;
}
.site-header__mobile-item-left-mark svg {
  margin-top: -3px;
}
.site-header__mobile-item-left-content svg {
  width: 27px;
  height: 27px;
  transition: 0.3s;
}
.site-header__mobile-item-left-content p {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  color: #000;
  transition: 0.3s;
}
.site-header__mobile-item-left-content:hover svg {
  color: #8a2be2;
}
.site-header__mobile-item-left-content:hover p {
  color: #8a2be2;
}
.site-header__mobile-right-logo svg {
  width: 33px;
  height: 33px;
  flex-shrink: 0;
  cursor: pointer;
  transition: 0.3s;
}
.site-header__mobile-right-logo:hover svg {
  color: #8a2be2;
}

@media only screen and (max-width: 1190px) {
  .site-header__list {
    column-gap: 50px;
  }
}
@media only screen and (max-width: 1010px) {
  .site-header__list {
    column-gap: 40px;
  }
}
@media only screen and (max-width: 960px) {
  .site-header__logo svg {
    width: 33px;
    height: 33px;
  }
  .site-header__logo h2 {
    font-size: 19px;
  }
  .site-header__item:nth-child(1) svg {
    width: 26.3px;
    height: 26.3px;
  }
  .site-header__item:nth-child(6) svg {
    width: 26.3px;
    height: 26.3px;
  }
}
@media only screen and (max-width: 920px) {
  .site-header__logo svg {
    width: 31.7px;
    height: 31.7px;
  }
  .site-header__logo h2 {
    font-size: 17px;
  }
  .site-header__item:nth-child(1) svg {
    width: 25.5px;
    height: 25.5px;
  }
  .site-header__item:nth-child(6) svg {
    width: 25.7px;
    height: 25.7px;
  }
  .site-header__item a {
    font-size: 16px;
  }
  .site-header__item button {
    font-size: 16px;
  }
}
@media only screen and (max-width: 900px) {
  .site-header__item:nth-child(2) {
    display: none;
  }
  .site-header__item:nth-child(3) {
    display: none;
  }
  .site-header__item:nth-child(4) {
    display: none;
  }
  .site-header__item:nth-child(5) {
    display: none;
  }
  .site-header__item:nth-child(6) {
    display: none;
  }
  .site-header__item:nth-child(7) {
    display: none;
  }
  .site-header__menu-logo {
    display: inline-block;
  }
  .site-header__logo svg {
    width: 33px;
    height: 33px;
  }
  .site-header__logo h2 {
    font-size: 19px;
  }
  .site-header__item:nth-child(1) svg {
    width: 27px;
    height: 27px;
  }
}
@media only screen and (max-width: 400px) {
  .site-header__logo svg {
    width: 31.7px;
    height: 31.7px;
  }
  .site-header__logo h2 {
    font-size: 17.7px;
  }
  .site-header__item:nth-child(1) svg {
    width: 25.7px;
    height: 25.7px;
  }
  .site-header__menu-logo svg {
    width: 26.7px;
    height: 26.7px;
  }
  .site-header__mobile-logo svg {
    width: 31.7px;
    height: 31.7px;
  }
  .site-header__mobile-logo h2 {
    font-size: 17.7px;
  }
  .site-header__mobile-darkmode svg {
    width: 25.7px;
    height: 25.7px;
  }
  .site-header__mobile-item-left-content svg {
    width: 26px;
    height: 26px;
  }
  .site-header__mobile-right-logo svg {
    width: 30px;
    height: 30px;
  }
}
@media only screen and (max-width: 341px) {
  .site-header__logo svg {
    width: 30px;
    height: 30px;
  }
  .site-header__logo h2 {
    font-size: 17.3px;
  }
  .site-header__item:nth-child(1) svg {
    width: 25px;
    height: 25px;
  }
  .site-header__menu-logo svg {
    width: 26px;
    height: 26px;
  }
  .site-header__mobile-logo svg {
    width: 30px;
    height: 30px;
  }
  .site-header__mobile-logo h2 {
    font-size: 17.3px;
  }
  .site-header__mobile-darkmode svg {
    width: 25px;
    height: 25px;
  }
}
