.dark {
  .site-header {
    background: #000;
    transition: 0.2s;
  }
  .site-header__logo svg {
    color: #fff;
    transition: 0.1s;
  }
  .site-header__logo h2 {
    color: #fff;
    transition: 0.1s;
  }
  .site-header__item:nth-child(1) svg {
    color: #fff;
    transition: 0.1s;
  }
  .site-header__item:nth-child(6) svg {
    color: #fff;
    transition: 0.1s;
  }
  .site-header__item a {
    color: #fff;
    transition: 0.1s;
  }
  .site-header__item button {
    background: #000;
    color: #fff;
    transition: 0.1s;
  }
  .site-header__item button:hover {
    border-color: #fff;
    transition: 0.1s;
  }
  .site-header__menu-logo svg {
    color: #fff;
    transition: 0.1s;
  }
  .site-header__mobile-list {
    background: #232536;
    transition: 0.1s;
  }
  .site-header__mobile-logo svg {
    color: #fff;
    transition: 0.1s;
  }
  .site-header__mobile-logo h2 {
    color: #fff;
    transition: 0.1s;
  }
  .site-header__mobile-darkmode svg {
    color: #fff;
    transition: 0.1s;
  }
  .site-header__mobile-item-left-content p {
    color: #fff;
    transition: 0.1s;
  }
  .site-header__mobile-item-left-content svg {
    color: #fff;
    transition: 0.1s;
  }
  .site-header__mobile-right-logo svg {
    color: #fff;
    transition: 0.1s;
  }
  .login {
    background: #000;
    transition: 0.1s;
  }
  .login__block {
    .login__text {
      color: #fff;
      transition: 0.1s;
    }
    .login__title {
      color: #00f;
      transition: 0.1s;
    }
  }
  .login__form-control {
    label {
      color: #fff;
      transition: 0.1s;
    }
    input {
      background: #000;
      color: #fff;
      transition: 0.1s;
      &::placeholder {
        color: #fff;
      }
    }
  }
  .signUp {
    background: #000;
    transition: 0.1s;
  }
  .signUp__block {
    .signUp__text {
      color: #fff;
      transition: 0.1s;
    }
    .signUp__title {
      color: #8a2be2;
      transition: 0.1s;
    }
  }
  .signUp__form-control {
    label {
      color: #fff;
      transition: 0.1s;
    }
    input {
      background: #000;
      color: #fff;
      transition: 0.1s;
      &::placeholder {
        color: #fff;
      }
    }
  }
  .forgot-password {
    background: #000;
    transition: 0.1s;
  }
  .forgot-password__title {
    color: #fff;
    transition: 0.1s;
  }
  .forgot-password__form-control input {
    border: 2px solid #fff;
    background: #000;
    color: #fff;
    transition: 0.1s;
    &::placeholder {
      color: #fff;
    }
  }
}
