html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
body {
  margin: 0;
}
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
  display: inline-block;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.container {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
img {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: inline-block;
}
